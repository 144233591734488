@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');

$color-gray-dark: #2a2b2e;
$color-pink-dark: #ff7c7c;
$color-pink-light: #ffb6b9;
$color-blue: #8deaff;
$color-yellow: #ffe084;
$color-white-almost: #f5f5f5;
$color-gray-light: #d9d9d9;
$color-gray-medium: #bdbdbd;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-gray-dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
