@import '../index';

.Day {
  width: 125px;
  height: 125px;
  border-radius: 5px;
  border: 1px solid $color-white-almost;
  position: relative;
  box-shadow: 5px 5px 10px black;
  cursor: pointer;
  margin: 6px;

  &-preview,
  &-details {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-preview {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-details {
    background-color: rgba(245, 245, 245, 0.87);
    border-radius: 3px;
    transition: opacity 150ms linear;
    opacity: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;

    &-day {
      position: absolute;
      top: 3px;
      left: 3px;
      font-family: 'Ubuntu Mono';
      font-weight: bold;
      font-size: 20px;
      color: $color-pink-dark;
    }

    &-title {
      color: black;
      padding: 0px 5px;
      font-family: 'Varela Round';
    }

    &-type {
      position: absolute;
      bottom: 2px;
      right: 5px;
    }
  }

  &:hover &-details {
    opacity: 1;
  }
}
