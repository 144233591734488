@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#2a2b2e}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#2a2b2e}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.Day{width:125px;height:125px;border-radius:5px;border:1px solid #f5f5f5;position:relative;box-shadow:5px 5px 10px black;cursor:pointer;margin:6px}.Day-preview,.Day-details{position:absolute;width:100%;height:100%}.Day-preview{display:flex;align-items:center;justify-content:center}.Day-details{background-color:rgba(245,245,245,0.87);border-radius:3px;transition:opacity 150ms linear;opacity:0;z-index:5;display:flex;align-items:center;justify-content:center;text-align:center;font-size:15px}.Day-details-day{position:absolute;top:3px;left:3px;font-family:'Ubuntu Mono';font-weight:bold;font-size:20px;color:#ff7c7c}.Day-details-title{color:black;padding:0px 5px;font-family:'Varela Round'}.Day-details-type{position:absolute;bottom:2px;right:5px}.Day:hover .Day-details{opacity:1}

.days{height:100px;width:100px}.full-image{display:flex;align-items:center;justify-content:center}.full-image img{max-width:100px;max-height:100px}.small-image{display:flex;align-items:center;justify-content:center}.small-image img{max-width:80px;max-height:80px}.rounded img{border-radius:5px}.Two{position:relative}.Two .menu,.Two .close{position:absolute}.Two .menu{top:0;left:0;height:45px;width:45px}.Two .close{bottom:0;right:0;height:40px;width:40px}.Three{position:relative}.Three img{height:45px;width:45px}.Three .vscode,.Three .scissors{position:absolute}.Three .vscode{bottom:10px;left:0}.Three .scissors{top:10px;right:0}.Eleven{display:flex;align-items:center;justify-content:center}.Eleven img{width:75px;-webkit-filter:drop-shadow(5px 5px 2px black);filter:drop-shadow(5px 5px 2px black)}.Twelve{position:relative}.Twelve .home,.Twelve .backtotop2{position:absolute;-webkit-filter:drop-shadow(5px 5px 2px black);filter:drop-shadow(5px 5px 2px black)}.Twelve .home{top:5px;left:50%;transform:translateX(-50%);width:100px}.Twelve .backtotop2{bottom:3px;right:3px;width:35px}.Thirteen{position:relative}.Thirteen img{height:45px;width:45px}.Thirteen .gatsby,.Thirteen .clock{position:absolute}.Thirteen .gatsby{bottom:5px;left:5px}.Thirteen .clock{top:5px;right:5px}.Sixteen{position:relative}.Sixteen img{height:45px;width:45px}.Sixteen .vscode,.Sixteen .spell_check{position:absolute}.Sixteen .spell_check{top:5px;left:8px}.Sixteen .vscode{bottom:15px;right:8px}.Seventeen{position:relative}.Seventeen img{height:37px;width:37px}.Seventeen .round,.Seventeen .square{position:absolute}.Seventeen .square{left:5px;top:50%;transform:translateY(-50%)}.Seventeen .round{right:5px;top:50%;transform:translateY(-50%)}.TwentyOne{display:flex;align-items:center;justify-content:center}.TwentyOne img{max-width:100px;max-height:100px;border-radius:50%;-webkit-filter:blur(3px);filter:blur(2px)}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#2a2b2e}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.App{display:flex;flex-direction:column;align-items:center;margin:20px 0}.blue-text{color:#8deaff}.dark-pink-text{color:#ff7c7c}.yellow-text{color:#ffe084}.light-pink-text{color:#ffb6b9}.title{color:#f5f5f5;font-family:'Varela Round';margin-bottom:20px;font-size:50px}.title-year{font-family:'Ubuntu Mono'}.title-link{text-decoration:none;transition:text-shadow 100ms}.title-link:hover{text-shadow:10px 10px 10px black}.calendar{margin-top:20px;max-width:973px;display:flex;flex-wrap:wrap;justify-content:flex-start}.empty{width:127px;height:127px;margin:6px}.about{display:flex;align-items:center;justify-content:center;color:white;font-size:1.5rem;font-family:'Varela Round';text-decoration:none;font-weight:bold;transition:text-shadow 100ms;width:127px;height:127px;margin:6px}.about:hover{text-shadow:10px 10px 10px black}.day-of-week{width:127px;margin:6px;font-family:'Ubuntu Mono';color:#f5f5f5;text-align:center;font-weight:bold}.subtitle{font-weight:bold;color:#d9d9d9;margin:0 40px 10px}.message{font-style:italic;color:#d9d9d9;margin:0 40px 20px}@media screen and (max-width: 973px){.day-of-week,.empty{display:none}.calendar{justify-content:center}}@media screen and (min-width: 480px){.message{display:none}}

