.days {
  height: 100px;
  width: 100px;
}

.full-image {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100px;
    max-height: 100px;
  }
}

.small-image {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 80px;
    max-height: 80px;
  }
}

.rounded {
  img {
    border-radius: 5px;
  }
}

.Two {
  position: relative;

  .menu,
  .close {
    position: absolute;
  }

  .menu {
    top: 0;
    left: 0;
    height: 45px;
    width: 45px;
  }

  .close {
    bottom: 0;
    right: 0;
    height: 40px;
    width: 40px;
  }
}

.Three {
  position: relative;

  img {
    height: 45px;
    width: 45px;
  }

  .vscode,
  .scissors {
    position: absolute;
  }

  .vscode {
    bottom: 10px;
    left: 0;
  }

  .scissors {
    top: 10px;
    right: 0;
  }
}

.Eleven {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 75px;
    -webkit-filter: drop-shadow(5px 5px 2px black);
    filter: drop-shadow(5px 5px 2px black);
  }
}

.Twelve {
  position: relative;

  .home,
  .backtotop2 {
    position: absolute;
    -webkit-filter: drop-shadow(5px 5px 2px black);
    filter: drop-shadow(5px 5px 2px black);
  }

  .home {
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
  }

  .backtotop2 {
    bottom: 3px;
    right: 3px;
    width: 35px;
  }
}

.Thirteen {
  position: relative;

  img {
    height: 45px;
    width: 45px;
  }

  .gatsby,
  .clock {
    position: absolute;
  }

  .gatsby {
    bottom: 5px;
    left: 5px;
  }

  .clock {
    top: 5px;
    right: 5px;
  }
}

.Sixteen {
  position: relative;

  img {
    height: 45px;
    width: 45px;
  }

  .vscode,
  .spell_check {
    position: absolute;
  }

  .spell_check {
    top: 5px;
    left: 8px;
  }

  .vscode {
    bottom: 15px;
    right: 8px;
  }
}

.Seventeen {
  position: relative;

  img {
    height: 37px;
    width: 37px;
  }

  .round,
  .square {
    position: absolute;
  }

  .square {
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .round {
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.TwentyOne {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    -webkit-filter: blur(3px);
    filter: blur(2px);
  }
}
