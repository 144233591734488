@import 'index';

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.blue-text {
  color: $color-blue;
}

.dark-pink-text {
  color: $color-pink-dark;
}

.yellow-text {
  color: $color-yellow;
}

.light-pink-text {
  color: $color-pink-light;
}

.title {
  color: $color-white-almost;
  font-family: 'Varela Round';
  margin-bottom: 20px;
  font-size: 50px;

  &-year {
    font-family: 'Ubuntu Mono';
  }

  &-link {
    text-decoration: none;
    transition: text-shadow 100ms;

    &:hover {
      text-shadow: 10px 10px 10px black;
    }
  }
}

.calendar {
  margin-top: 20px;
  max-width: 973px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.empty {
  width: 127px;
  height: 127px;
  margin: 6px;
}

.about {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  font-family: 'Varela Round';
  text-decoration: none;
  font-weight: bold;
  transition: text-shadow 100ms;
  width: 127px;
  height: 127px;
  margin: 6px;

  &:hover {
    text-shadow: 10px 10px 10px black;
  }
}

.day-of-week {
  width: 127px;
  margin: 6px;
  font-family: 'Ubuntu Mono';
  color: $color-white-almost;
  text-align: center;
  font-weight: bold;
}

.subtitle {
  font-weight: bold;
  color: $color-gray-light;
  margin: 0 40px 10px;
}

.message {
  font-style: italic;
  color: $color-gray-light;
  margin: 0 40px 20px;
}

@media screen and (max-width: 973px) {
  .day-of-week,
  .empty {
    display: none;
  }

  .calendar {
    justify-content: center;
  }
}

@media screen and (min-width: 480px) {
  .message {
    display: none;
  }
}
